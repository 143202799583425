import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Badge, Button, Form, Spinner, NavLink, Tab, Nav } from 'react-bootstrap';
import ResponsiveNav from 'components/ResponsiveNav';
import moment from 'moment';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { getCurrentSellerId } from 'auth/utils';
import { Steps } from 'rsuite';
import { displayErrorToast, displaySuccessToast } from 'helpers/toaster';
import { STATUS_COLOR, STATUS_DELIVERED, STATUS_OUT_FOR_DELIVERY, STATUS_PICKED, STATUS_READY_TO_PICKUP } from 'constants/index';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SupportTicketsDetail from 'views/support/SupportTicketsDetail';
import DeliveryRemarks from 'views/support/DeliveryRemarks';
import QuestionWidget from 'views/messages/components/ViewQuestionDetail/QuestionWidget';
import 'react-datepicker/dist/react-datepicker.css';
import isEmpty from 'lodash.isempty';
import { calculateDistance, citiesGeo } from 'helpers/gmap';
import {
  updateSellerOrderStatus,
  getOrderStatusCounts,
  createOrderAWB,
  getOrderDetails,
  getOrderStatusTrackingInfo,
  // getOrderTransactions,
} from './actions/orderActions';
import './Orders.style.scss';
import DeliveryDetailsModal from './components/DeliveryDetailsModal';
import ReturnDetails from './components/ReturnDetails';

/* const steps = [
  {
    stepId: 'created',
    title: 'Created',
    description: <span className="text-muted">03-06-2022 11:20 am</span>,
    stepCompleted: true,
  },
  {
    stepId: 'sellerConfirmation',
    title: 'Confirmation',
    description: (
      <span className="text-muted">
        Confirmed by <b>Abdul Bari</b> at 03-06-2022 11:20 am
      </span>
    ),
    stepCompleted: true,
  },
  {
    stepId: 'packed',
    title: 'Packed',
    description: (
      <span className="text-muted">
        Packed by <b>Warehouse Team</b> at 03-06-2022 11:20 am
      </span>
    ),
    stepCompleted: true,
  },
  {
    stepId: 'picked',
    title: 'Shipment',
    description: <span className="text-muted">Picked by EPG at at 03-06-2022 11:20 am</span>,
    stepCompleted: true,
  },
  {
    stepId: 'delivery_status',
    title: 'Delivered',
    onProgress: true,
  },
  {
    stepId: 'invoiced',
    title: 'Invoice',
  },
  {
    stepId: 'grn_do',
    title: 'GRN & DO',
  },
  {
    stepId: 'payment_processed',
    title: 'Payment Processed',
  },
]; */

const steps = [
  {
    stepId: 'created',
    title: 'Created',
    description: <span className="text-muted">03-06-2022 11:20 am</span>,
    stepCompleted: true,
  },
  {
    stepId: 'sellerConfirmation',
    title: 'Confirmation',
    description: [],
    stepCompleted: false,
  },
  {
    stepId: 'packed',
    title: 'Packed',
    description: [],
    stepCompleted: false,
  },
  {
    stepId: 'picked',
    title: 'Shipment',
    description: [],
    stepCompleted: false,
  },
  {
    stepId: 'delivery_status',
    title: 'Delivered',
    description: [],
  },
  {
    stepId: 'invoice_generated',
    title: 'Invoice',
    description: [],
  },
  {
    stepId: 'grn_do',
    title: 'GRN & DO',
    description: [],
  },
  {
    stepId: 'payment_processed',
    title: 'Payment Processed',
    description: [],
  },
];

const OrdersViewDetail = ({ id: idFromProps, detailFromProps = {} }) => {
  const { id = idFromProps } = useParams();
  // const [isShown, setIsShown] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [stsLoad, setStatusLoad] = useState(false);
  const [isOrderLoading, setOrderLoading] = useState(true);
  const [stsAwbLoad, setAWBLoad] = useState(false);
  const [isLoadVal, setActionLoad] = useState('');

  const dispatch = useDispatch();
  const [orderDetail, setOrderDetail] = useState(detailFromProps || {});
  const [statusInfo, setStatusInfo] = useState([]);
  const [shipedDate, setShipedDate] = useState('');
  const [deliveryInfo, setDeliveryInfo] = useState({});

  // const [shippingCharge, setShippingCharge] = useState(null);
  // const [totalVAT, setTotalVAT] = useState(0);
  // const [totalCommission, setTotalCommission] = useState(0);
  // const [subTotal, setSubTotal] = useState(0);
  // const [returnValue, setReturnValue] = useState(0);
  // const [grandTotal, setGrandTotal] = useState(0);
  // const [statusSteps, setStatusSteps] = useState(steps);

  const fetchOrderDetail = async () => {
    try {
      if (!id) return false;
      // if (!isEmpty(detailFromProps)) {
      //   setOrderLoading(false);
      // } else {
      //   setOrderLoading(true);
      // }
      setOrderLoading(true);
      const result = await getOrderDetails({
        params: {
          country: 'AE',
          orderReferenceNumber: id,
        },
      });
      setOrderDetail(result);
      setOrderLoading(false);
      if (result?.delivery_info) {
        setDeliveryInfo(JSON.parse(result.delivery_info));
      }
      if (window.location.search.includes('debug')) {
        steps.forEach((step) => {
          step.description = [];
        });
        const createdDate = moment(Number(result.created_at)).format('DD-MM-YYYY hh:mm a');
        steps[0].description = [
          <span key={0} className="text-muted">
            {createdDate}
          </span>,
        ];
        if (result.payment_processed_on) {
          steps[7].stepCompleted = true;
          steps[7].description = [
            <span key={0} className="text-muted">
              {moment(Number(result.payment_processed_on)).format('DD-MM-YYYY hh:mm a')}
            </span>,
          ];
        }
        // setStatusSteps(steps);
        const orderId = result.order_id;
        const trackingResult = await getOrderStatusTrackingInfo({
          params: {
            orderId,
          },
        });
        setStatusInfo(trackingResult);

        trackingResult.forEach((item) => {
          const index = steps.findIndex((step) => step.stepId === item.step_id);
          const statusMessage = `${item.action} by ${item.created_by_user_name} at ${moment(Number(item.created_at)).format('DD-MM-YYYY hh:mm a')}`;
          if (item.step_id === 'picked') {
            setShipedDate(moment(Number(item.created_at)).format('DD-MM-YYYY hh:mm a'));
          }
          console.log('Tracking Item:', item);
          if (index >= 0) {
            steps[index].stepCompleted = true;
            console.log('Step Item:', steps[index]);
            if (steps[index].description) {
              steps[index].description.push(<span className="text-muted">{statusMessage}</span>);
            } else {
              steps[index].description = [
                <span key={index} className="text-muted">
                  {statusMessage}
                </span>,
              ];
            }
          } else if (steps[index]) {
            steps[index].description = [];
          }
        });
        console.log('Tracking Info:', statusInfo);
      }
      setOrderLoading(false);

      return true;
    } catch (e) {
      return displayErrorToast('Something went wrong. please refresh the page to see the order');
    }
  };

  // const fetchOrderTransactions = async () => {
  //   const result = await getOrderTransactions({
  //     params: {
  //       orderReferenceNumber: id,
  //     },
  //   });
  //   if (result?.length) {
  //     const valueTransaction = result.find((transaction) => transaction.transaction_type === 'order_created');
  //     const returnTransactions = result.filter((transaction) => transaction.transaction_type === 'items_returned');
  //     const returns = returnTransactions.reduce((total, item) => {
  //       return total + item.transaction_value;
  //     }, 0);
  //     setReturnValue(Math.abs(returns));
  //     const returnVAT = returnTransactions.reduce((total, item) => {
  //       return total + item.total_vat;
  //     }, 0);
  //     const commissionTransaction = result.filter(
  //       (transaction) => transaction.transaction_type === 'order_commission' || transaction.transaction_type === 'order_commission_reversed'
  //     );
  //     const commissionValue = commissionTransaction.reduce((total, item) => {
  //       return total + item.transaction_value;
  //     }, 0);
  //     const commissionVAT = commissionTransaction.reduce((total, item) => {
  //       return total + item.total_vat;
  //     }, 0);
  //     const shippingTransaction = result.filter(
  //       (transaction) => transaction.transaction_type === 'shipping_charge' || transaction.transaction_type === 'shipping_charge_reversed'
  //     );
  //     const shippingFee = shippingTransaction.reduce((total, item) => {
  //       return total + item.transaction_value;
  //     }, 0);
  //     // const totalVat = Number(valueTransaction?.total_vat + commissionTransaction?.total_vat).toFixed(2);
  //     const totalVat = Number(valueTransaction?.total_vat + returnVAT + commissionVAT).toFixed(2);
  //     // const total = (valueTransaction?.transaction_value + commissionTransaction?.transaction_value).toFixed(2);
  //     setTotalVAT(totalVat);
  //     // setTotalCommission(Math.abs(Number(commissionTransaction?.transaction_value)).toFixed(2));
  //     setTotalCommission(Math.abs(Number(commissionValue)).toFixed(2));
  //     setSubTotal(Number(valueTransaction?.transaction_value).toFixed(2));
  //     const netAmount = Number(valueTransaction?.transaction_value) + commissionValue + returns + shippingFee;
  //     if (shippingFee > 0) {
  //       setShippingCharge({ shippingFee: Math.abs(shippingFee), sign: '+' });
  //     } else setShippingCharge({ shippingFee: Math.abs(shippingFee), sign: '-' });
  //     setGrandTotal(netAmount.toFixed(2));
  //   }
  // };

  useEffect(() => {
    if (id) {
      fetchOrderDetail();
      // fetchOrderTransactions();
    }
  }, [id]);

  useEffect(() => {}, [statusInfo]);
  const history = useHistory();
  // const orderDetail = orderDetail?.OrderDetails?.[0]?.orderInfo?.[0];

  const itemData = orderDetail?.items;
  const returnList = orderDetail?.returns || [];
  const title = 'Order Detail';
  const description = 'Order Detail View';

  const [oStatus, setOrderStatus] = useState(0);
  const { orderStatus } = useSelector((state) => state.orderStatus);
  const { orderAWB } = useSelector((state) => state.orderAWB);
  const [isAwb, setAwbOrder] = useState('');
  const [grandTotal, setGrandTotal] = useState(0);

  const accBtnId = 'acc-1';
  const recBtnId = 'rec-1';
  console.log(oStatus);
  /*   const handleClick = () => {
      // 👇️ only show element on click
      setIsShown(!isShown);
    }; */

  useEffect(() => {
    let total = 0;
    orderDetail?.items?.forEach((item) => {
      if (item?.qty_ordered && item?.now_price) {
        total += (item.qty_ordered - item.qty_returned) * item.now_price;
      }
    });
    if (orderDetail?.shipping_amt) {
      total += orderDetail.shipping_amt;
    }
    if (orderDetail?.discount_amt) {
      total -= orderDetail.shipping_amt;
    }
    setGrandTotal(total);
  }, [orderDetail]);
  const handleAWBSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    console.log(event.currentTarget.id);
    console.log(event.target.elements.noofbox.value);

    if (!form.checkValidity() === false) {
      setAWBLoad({ stsAwbLoad: true });
      createOrderAWB({
        params: {
          country: 'AE',
          order_ref: event.target.elements.orderRef.value,
          seller_id: getCurrentSellerId(),
          noofbox: event.target.elements.noofbox.value,
        },
        dispatch,
      });
    }
    setAwbOrder(event.currentTarget.id);

    // setValidated(true);
  };

  useEffect(() => {
    if (Object.keys(orderAWB).length > 0) {
      setAWBLoad({ stsAwbLoad: false });
      if (orderAWB.status === 100) {
        displaySuccessToast(orderAWB.message);
      } else {
        displayErrorToast(orderAWB.message);
      }
      const min = 5;
      const max = 10;
      const random = min + Math.random() * (max - min);
      setOrderStatus(random);
      setAwbOrder('');
    }
  }, [orderAWB]);

  const handleActionClick = (od, sts, index) => {
    // 👇️ only show element on click
    setActionLoad(index);
    setStatusLoad({ stsLoad: true });
    updateSellerOrderStatus({
      params: {
        country: 'AE',
        order_status: sts,
        seller_id: getCurrentSellerId(),
        order_id: od.reference_no,
      },
      dispatch,
    });
  };
  useEffect(() => {
    if (Object.keys(orderStatus).length > 0) {
      setStatusLoad({ stsLoad: false });
      if (orderStatus.code === '100') {
        displaySuccessToast(orderStatus.message);
      } else {
        displayErrorToast(orderStatus.message);
      }
      const min = 1;
      const max = 10;
      const random = min + Math.random() * (max - min);
      setOrderStatus(random);
      setActionLoad('');
    }
  }, [orderStatus]);

  useEffect(() => {
    if (isEmpty(detailFromProps)) {
      getOrderStatusCounts({
        params: {
          country: 'AE',
          seller_id: getCurrentSellerId(),
        },
        dispatch,
      });
    }
  }, [orderStatus]);

  const renderStatus = () => {
    const status = STATUS_COLOR.find((x) => Number(x.id) === orderDetail.order_status);
    console.log('status---------', status, '--------');
    const color = status?.color || 'muted';
    const sts = status?.lsStatus || 'Pending';

    return <Badge bg={color}>{sts} </Badge>;
  };

  const selectedCity = citiesGeo[orderDetail.city] || citiesGeo[orderDetail.region];
  let distance;
  if (selectedCity) {
    distance = calculateDistance({ lat1: selectedCity.latitude, lon1: selectedCity.longitude, lat2: orderDetail.latitude, lon2: orderDetail.longitude });
    // console.log(`Distance: ${distance.toFixed(2)} km`);
  }

  return (
    <>
      <If condition={isOrderLoading}>
        <div className="align-items-center d-flex justify-content-center sh-80 text-center">
          <Spinner animation="border" size="sm" variant="primary" className="dzu-spinner-border" />
        </div>
      </If>
      <If condition={orderDetail.cancelled_by_customer}>
        <div
          className="align-items-center d-flex justify-content-center sh-80 text-center position-absolute"
          style={{ width: '100%', background: '#b77c7c33', zIndex: 100, color: 'red', 'font-size': '33px' }}
        >
          Cancelled
        </div>
      </If>
      {showModal && (orderDetail.order_status === STATUS_PICKED || orderDetail.order_status === STATUS_OUT_FOR_DELIVERY) && (
        <DeliveryDetailsModal
          unDelivered
          show={showModal}
          onHide={() => setShowModal(false)}
          order={orderDetail}
          onComplete={() => console.log('status updated')}
        />
      )}
      {!isOrderLoading && orderDetail.reference_no === id ? (
        <>
          <HtmlHead title={title} description={description} />
          <Col>
            {/* Title Start */}
            <div className="page-title-container mb-3">
              <Row>
                <NavLink data-testid="productsBackButton" className="muted-link pb-1 d-inline-block hidden breadcrumb-back" onClick={() => history.goBack()}>
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">List of orders</span>
                </NavLink>
                <Col className="mb-2">
                  <h1 className="mb-2 pb-0 display-4">
                    <span className="text-muted">Order # </span>
                    {orderDetail.reference_no}
                  </h1>
                </Col>
              </Row>

              <Row>
                <Col className="mb-5 mb-xxl-0">
                  {/* Order Details Start */}
                  <Row className="row-cols-12 row-cols-lg-12 g-2 mb-5">
                    <Card className=" ">
                      <Card.Header className="p-2">
                        <div className="float-start">
                          <span className="text-muted">Total Order Amount:</span> <b>AED {grandTotal}</b>
                        </div>
                        <div className="float-end">
                          <If condition={orderDetail.order_status === 3 && orderDetail.is_supplier_delivery}>
                            <span
                              className="visible-hover cursor-pointer p-2"
                              data-toggle="tooltip"
                              title="Set Un Delivered"
                              onClick={() => {
                                setShowModal(true);
                              }}
                            >
                              <CsLineIcons width={12} height={12} icon="link" className="text-small" />
                            </span>
                          </If>
                          <span className="text-medium text-muted text-truncate text-end">{renderStatus(orderDetail.order_status)}</span>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-2">
                        <Row className="row-cols-12 row-cols-lg-12 g-2 ">
                          <Col lg={6}>
                            <Card className="mb-2 border-default sh-25">
                              <Row className="g-0">
                                <Col>
                                  <Card.Body className="d-flex flex-column p-3  justify-content-center">
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="fw-bold ">Purchased Date</div>
                                      <div className="text-medium text-success text-truncate ">
                                        {moment(Number(orderDetail.created_at)).format('DD-MM-YYYY hh:mm a')}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mt-2">
                                      <div className="fw-bold ">Shipped Date</div>
                                      <div className="text-medium text-success text-truncate ">{shipedDate}</div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="fw-bold ">Delivery Date Shown to Customer</div>
                                      <div className="text-medium text-truncate ">
                                        {orderDetail.delivery_sla && moment(Number(orderDetail.delivery_sla)).format('DD-MM-YYYY')}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="fw-bold ">Delivered Date Set By Seller</div>
                                      <div className="text-medium  text-truncate ">
                                        {orderDetail.expected_delivery_date && moment(Number(orderDetail.expected_delivery_date)).format('DD-MM-YYYY')}
                                      </div>
                                    </div>
                                    <If condition={orderDetail?.delivery_reschedule_to}>
                                      <div className="d-flex flex-row justify-content-between">
                                        <div className="fw-bold ">Rescheduled To</div>
                                        <div className="text-medium  text-truncate ">
                                          {orderDetail.delivery_reschedule_to && moment(Number(orderDetail.delivery_reschedule_to)).format('DD-MM-YYYY')} (
                                          {Number(orderDetail.delivery_reschedule_to) === Number(orderDetail.expected_delivery_date)
                                            ? 'Approved'
                                            : 'Approval Pending'}
                                          )
                                        </div>
                                      </div>
                                    </If>
                                    <div className="d-flex flex-row justify-content-between mt-2">
                                      <div className="fw-bold ">Delivered Date</div>
                                      <div className="text-medium text-muted text-truncate ">
                                        {orderDetail.delivery_completed_at && moment(Number(orderDetail.delivery_completed_at)).format('DD-MM-YYYY')}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mt-2">
                                      <div className="fw-bold ">Invoiced Date</div>
                                      <div className="text-medium text-muted text-truncate ">
                                        {orderDetail.invoice_date && moment(Number(orderDetail.invoice_date)).format('DD-MM-YYYY')}
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col lg={6}>
                            <Card className="mb-2 border-default sh-25">
                              <Row className="g-0">
                                <Col>
                                  <Card.Body className="d-flex flex-column p-3  justify-content-center">
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="fw-bold ">Shipment Type</div>
                                      <div className="text-medium text-success text-truncate ">
                                        <If condition={orderDetail.order_status > STATUS_READY_TO_PICKUP}>
                                          {orderDetail.is_supplier_delivery ? 'Self Ship' : 'Easy Ship'}
                                        </If>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between  mt-2">
                                      <div className="fw-bold ">Fullfillment</div>
                                      <div className="text-medium text-success text-truncate ">
                                        <If condition={orderDetail.order_status > STATUS_READY_TO_PICKUP}>
                                          {orderDetail.is_supplier_delivery ? 'Self' : 'Emirates Post'}
                                        </If>
                                      </div>
                                    </div>
                                    <If condition={!orderDetail.awb_no}>
                                      <div className="d-flex flex-row justify-content-between  mt-2">
                                        <div className="fw-bold">AWB</div>
                                        <div className="text-medium text-muted text-truncate">
                                          {orderDetail.is_supplier_delivery ? 'NA' : orderDetail.awb_no}
                                        </div>
                                      </div>
                                    </If>
                                    <If condition={deliveryInfo.deliverySlot}>
                                      <div className="d-flex flex-row justify-content-between  mt-2">
                                        <div className="fw-bold">Delivery Slot</div>
                                        <div className="text-medium text-muted text-truncate">{deliveryInfo.deliverySlot}</div>
                                      </div>
                                    </If>
                                    <If condition={deliveryInfo?.driverName}>
                                      <div className="d-flex flex-row justify-content-between  mt-2">
                                        <div className="fw-bold">Driver Name</div>
                                        <div className="text-medium text-muted text-truncate">{deliveryInfo.driverName}</div>
                                      </div>
                                    </If>
                                    <If condition={deliveryInfo?.driverNumber}>
                                      <div className="d-flex flex-row justify-content-between  mt-2">
                                        <div className="fw-bold">Driver's Number</div>
                                        <div className="text-medium text-muted text-truncate">{deliveryInfo.driverNumber}</div>
                                      </div>
                                    </If>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Col>

                          <Col lg={6}>
                            <Card className="mb-2 border-default sh-25">
                              <Row className="g-0">
                                <Col>
                                  <Card.Body className="d-flex flex-column p-3  justify-content-center">
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="fw-bold ">LPO</div>
                                      <If condition={orderDetail.po_number}>
                                        <div className="text-medium text-success text-truncate ">
                                          {orderDetail.po_number} -{' '}
                                          <a
                                            className="text-medium text-success text-truncate"
                                            href={`https://seller.danubehome.com/uploads/lslpo/${orderDetail.po_number}.pdf?v=1`}
                                            download
                                          >
                                            <CsLineIcons icon="download" size={14} />
                                          </a>
                                        </div>
                                      </If>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between  mt-2">
                                      <div className="fw-bold ">Invoice #</div>
                                      <If condition={orderDetail.invoice_no}>
                                        <div className="text-medium text-success text-truncate ">
                                          {orderDetail.invoice_no}{' '}
                                          <a className="text-medium text-success text-truncate" href={`https://${orderDetail.payment_invoice_doc}`} download>
                                            <CsLineIcons icon="download" size={14} />
                                          </a>
                                        </div>
                                      </If>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between  mt-2">
                                      <div className="fw-bold ">Credit Notes</div>
                                      <If condition={orderDetail.returns?.length && orderDetail.returns?.[0].credit_note_url}>
                                        <div className="text-medium text-success text-truncate ">
                                          {orderDetail.returns.map((item) => {
                                            return (
                                              <>
                                                {item.credit_note_number} - {moment(Number(item.credit_note_date)).format('DD/MM/YYYY')}{' '}
                                                <a className="text-medium text-success text-truncate" href={`https://${item.credit_note_url}`} download>
                                                  <CsLineIcons icon="download" size={14} />
                                                </a>
                                                <br />
                                              </>
                                            );
                                          })}
                                        </div>
                                      </If>
                                    </div>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col lg={6}>
                            <Card className="mb-2 border-default sh-25">
                              <Row className="g-0">
                                <Col>
                                  <Card.Body className="d-flex flex-column p-3  justify-content-center">
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="text-medium text-primary text-truncate text-semi-large">
                                        {orderDetail.first_name} {orderDetail.last_name}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="text-medium text-muted text-truncate ">
                                        <a href={`mailto:${orderDetail.email}`}>
                                          <CsLineIcons icon="email" className="pe-1" />
                                          {orderDetail.email}
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="text-medium text-muted text-truncate ">
                                        <a href={`tel:${orderDetail.phone}`}>
                                          {' '}
                                          <CsLineIcons icon="phone" className="pe-1" />
                                          {orderDetail.phone}
                                        </a>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mt-2">
                                      <div className="text-medium text-muted text-bold">{orderDetail.address1}</div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="text-medium text-muted text-truncate ">{orderDetail.city}</div>
                                    </div>
                                    <If condition={distance >= 30}>
                                      <div className="d-flex flex-row justify-content-between">
                                        <div className="text-medium text-danger text-truncate">
                                          <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`https://www.google.com/maps/place/${orderDetail.latitude},${orderDetail.longitude}`}
                                            className="text-danger"
                                          >
                                            {(distance + distance * 0.15).toFixed()}km from {orderDetail.region}
                                          </a>
                                        </div>
                                      </div>
                                    </If>
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="text-medium text-muted text-truncate ">
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={`https://www.google.com/maps/place/${orderDetail.latitude},${orderDetail.longitude}`}
                                        >
                                          Google Map Location
                                        </a>
                                      </div>
                                      <div className="text-small">
                                        {/* <CsLineIcons icon="share" className="pe-1" size={14} /> */}
                                        <img src="/whatsapp.png" alt="whatsapp" style={{ width: 12, height: 12 }} />
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={`https://web.whatsapp.com/send?text=${orderDetail.first_name} ${orderDetail.last_name}%0A${orderDetail.phone}%0A${orderDetail.address1}%0Ahttps://www.google.com/maps/place/${orderDetail.latitude},${orderDetail.longitude}%0A`}
                                        >
                                          Send to WhatsApp
                                        </a>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>
                  {/* Order Details End */}
                  <If condition={window.location.search.includes('debug')}>
                    <Row className="row-cols-12 row-cols-lg-12 g-2 mb-5 border border-dashed  p-2 ">
                      <Col>
                        <Steps current={1} small>
                          {steps.map((step, index) => {
                            console.log(statusInfo);
                            let stepStatus = <Badge bg="outline-primary rounded-circle">{index + 1}</Badge>;
                            if (step.onProgress) {
                              stepStatus = <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
                            } else if (step.stepCompleted) {
                              stepStatus = <CsLineIcons icon="check" size={14} />;
                            }

                            return (
                              <Steps.Item
                                status="wait"
                                icon={stepStatus}
                                key={step.stepId}
                                title={<Badge bg={step.stepCompleted ? 'outline-success' : 'outline-secondary'}>{step.title}</Badge>}
                                description={
                                  <div>
                                    {step.description}
                                    <br />
                                  </div>
                                }
                              />
                            );
                          })}
                        </Steps>
                      </Col>
                    </Row>
                  </If>
                  {/* Order Item Details Start */}
                  <Row className="row-cols-12 row-cols-lg-12 g-2 mb-5">
                    <Card className="h-100">
                      <Card.Header className="p-2">
                        <Row className="g-2 mb-2">
                          <Col md="6" lg="6" xs="6">
                            <b>Item Details</b>
                          </Col>
                          <Col md="6" lg="6" xs="6" className="text-end" data-id={orderDetail.order_status}>
                            <div
                              className={`d-grid gap-2 d-md-flex justify-content-md-end  ${orderDetail.order_status === '1' ? 'acceptActive' : 'acceptHide'}`}
                            >
                              <Form
                                id={accBtnId}
                                noValidate
                                className={`d-grid gap-2 d-md-flex justify-content-md-end ${isAwb === accBtnId ? 'was-validated' : ''}`}
                                onSubmit={handleAWBSubmit}
                              >
                                <Form.Group controlId={accBtnId}>
                                  <Form.Control name="noofbox" type="number" size="medium" placeholder="No of Boxes" required />
                                  <Form.Control name="orderRef" type="hidden" size="sm" value={orderDetail.reference_no} />
                                  <Form.Control.Feedback type="invalid">Please Enter No Of Boxes.</Form.Control.Feedback>
                                </Form.Group>

                                <Button type="submit" disabled={stsAwbLoad && isAwb === accBtnId} variant="success" data-id={accBtnId}>
                                  {stsAwbLoad && isAwb === accBtnId && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                  Ready To Pickup
                                </Button>
                              </Form>
                            </div>
                            <div
                              className={`d-grid gap-2 d-md-flex justify-content-md-end  ${orderDetail.order_status === '0' ? 'acceptActive' : 'acceptHide'}`}
                            >
                              <Button
                                disabled={stsLoad && isLoadVal === accBtnId}
                                onClick={() => handleActionClick(orderDetail, '1', accBtnId)}
                                variant="success"
                                data-id={accBtnId}
                              >
                                {stsLoad && isLoadVal === accBtnId && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                Accept
                              </Button>
                              <Button
                                disabled={stsLoad && isLoadVal === recBtnId}
                                onClick={() => handleActionClick(orderDetail, '2', recBtnId)}
                                variant="danger"
                                data-id={recBtnId}
                              >
                                {' '}
                                {stsLoad && isLoadVal === recBtnId && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}{' '}
                                Reject
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="pb-0">
                        {/* List Header Start */}
                        <Row className="g-0 h-100 align-content-center d-none d-md-flex ps-5 pe-5 mb-2 custom-sort">
                          <Col md="1" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                            <div className=" text-medium cursor-pointer fw-bold">S.No</div>
                          </Col>
                          <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
                            <div className="text-medium cursor-pointer fw-bold">Image</div>
                          </Col>
                          <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
                            <div className=" text-medium cursor-pointer fw-bold">Name</div>
                          </Col>
                          <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
                            <div className=" text-medium cursor-pointer fw-bold">Sku</div>
                          </Col>
                          <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
                            <div className=" text-medium cursor-pointer fw-bold">Qty</div>
                          </Col>
                          <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
                            <div className="text-medium cursor-pointer fw-bold">Price</div>
                          </Col>
                          <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
                            <div className="text-medium cursor-pointer fw-bold">Discount</div>
                          </Col>
                          <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
                            <div className="text-medium cursor-pointer fw-bold">Commission</div>
                          </Col>
                          <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
                            <div className="text-medium cursor-pointer fw-bold">Total</div>
                          </Col>
                          <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
                            <div className="text-medium cursor-pointer fw-bold">Action</div>
                          </Col>
                        </Row>
                        {/* List Header End */}

                        {/* List Items Start */}
                        {(itemData || []).map((item, index) => {
                          return (
                            <Card className="border-default mb-2" key={item.sku}>
                              <Card.Body className="pt-0 pb-0 " key={item.sku}>
                                <Row className="g-0 h-100 align-content-center cursor-default">
                                  <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">S.No</div>
                                    <div className="text-body fw-bold text-medium text-muted text-wrap">{index + 1}</div>
                                  </Col>
                                  <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Image</div>
                                    <div className="sw-10 me-1 mb-1 d-inline-block">
                                      <img src={item.image} className="img-fluid rounded-md prod-item-image" alt="thumb" />
                                    </div>
                                  </Col>
                                  <Col xs="12" md="2" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Name</div>
                                    <div className="text-body fw-bold  text-medium text-muted text-wrap">{item.name}</div>
                                  </Col>
                                  <Col xs="12" md="2" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Sku</div>
                                    <div className="text-body fw-bold text-medium text-muted text-wrap">
                                      {item.sku} &middot; {item.partner_sku}
                                    </div>
                                  </Col>
                                  <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Qty</div>
                                    <div className="text-body fw-bold text-medium text-muted text-wrap">
                                      {item.qty_ordered} {item.qty_returned ? `(${item.qty_returned} Return)` : ''}
                                    </div>
                                  </Col>
                                  <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Price</div>
                                    <div className="text-body fw-bold text-medium text-muted text-wrap">{item.actual_now_price || item.now_price}</div>
                                  </Col>
                                  <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Discount</div>
                                    <div className="text-body fw-bold text-medium text-muted text-wrap">
                                      {(item.actual_now_price ? item.actual_now_price - item.now_price : 0).toFixed(2)}
                                    </div>
                                  </Col>
                                  <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Commission</div>
                                    <div className="text-body fw-bold text-medium text-muted text-wrap">{item.item_commission}</div>
                                  </Col>
                                  <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Total</div>
                                    <div className="text-body fw-bold text-medium text-muted text-wrap">{(item.now_price * item.qty_ordered).toFixed(2)}</div>
                                  </Col>
                                  <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                                    <div className="text-muted text-small d-md-none">Action</div>
                                    <div className="text-body fw-bold text-medium text-muted text-wrap">{renderStatus(item.item_status)}</div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  </Row>
                  {/* Order Item Details End */}
                  <If condition={returnList?.length}>
                    <ReturnDetails returns={returnList} />
                  </If>
                </Col>
              </Row>
              {/* Customer Details Start */}

              {/* Order Details Start */}
              <Row className="row-cols-12 row-cols-lg-12 g-2 mb-5">
                <Col md="4" lg="3" xs="6">
                  {/* <Card>
                    <Card.Header style={{ padding: '15px' }}>Customer details</Card.Header>
                    <Card.Body style={{ padding: '15px' }}>
                      <Card.Title>{orderDetail.full_name}</Card.Title>
                      <Card.Text>
                        {orderDetail.city} , {orderDetail.region}, {orderDetail.country} .
                      </Card.Text>
                      <Card.Text>Email : {orderDetail.email}</Card.Text>
                      <Card.Text>Phone : {orderDetail.phone}</Card.Text>
                      <Card.Text>
                        Location: <Badge bg="primary">View Map</Badge>
                      </Card.Text>
                    </Card.Body>
                  </Card> */}
                </Col>
                <Col md="4" lg="3" xs="6">
                  {/* <Card>
                    <Card.Header style={{ padding: '15px' }}>Supplier details</Card.Header>
                    <Card.Body style={{ padding: '15px' }}>
                      <Card.Title>Hassani Trading Company</Card.Title>
                      <Card.Text>DIP , Dubai - Dubai.</Card.Text>
                      <Card.Text>Email : Anil.Nair@hassani.ae</Card.Text>
                      <Card.Text>Phone : 971557440095</Card.Text>
                      <Card.Text>
                        Location: <Badge bg="primary">View Map</Badge>
                      </Card.Text>
                    </Card.Body>
                  </Card> */}
                </Col>
                {/* <Col md="4" lg="1" xs="6">
                  <span style={{ color: '#fff' }}> -</span>
                </Col> */}
                <Col md="4" lg="5" xs="6">
                  <Card>
                    {/* <Card.Header style={{ padding: '15px' }}>
                      
                      <div className="row g-0 h-100">
                        
                        <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around fw-bold">Sub Total (Inc.VAT)</div>
                        

                       
                        <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                          {orderDetail.currency} {subTotal}
                        </div>
                        
                      </div>
                    </Card.Header>
                    <Card.Body style={{ padding: '15px' }}>
                      <div className="container-fluid p-0 pb-3 h-100 position-relative">
                        <div className="row g-0 h-100">
                          <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around fw-bold">VAT (5%)</div>
                          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                            {orderDetail.currency} {totalVAT}
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid p-0 pb-3 h-100 position-relative">
                        <div className="row g-0 h-100">
                          <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around fw-bold">Commission (-)</div>
                          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                            {orderDetail.currency} {totalCommission}
                          </div>
                        </div>
                      </div>
                      
                      {returnValue ? (
                        <div className="container-fluid p-0 pb-3 h-100 position-relative">
                          <div className="row g-0 h-100">
                            <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around">Return/Refund (-) </div>
                            <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                              {orderDetail.currency} {Number(returnValue).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {shippingCharge && (
                        <div className="container-fluid p-0 pb-3 h-100 position-relative">
                          <div className="row g-0 h-100">
                            <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around">
                              Shipping Fee ({shippingCharge?.sign}){' '}
                            </div>
                            <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                              {orderDetail.currency} {Number(shippingCharge?.shippingFee).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      )}
                      
                      <div className="container-fluid p-0 pb-3 h-100 position-relative">
                        <div className="row g-0 h-100">
                          <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around fw-bold">Total</div>
                          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                            {orderDetail.currency} {grandTotal}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer style={{ padding: '15px' }}>
                      <div className="container-fluid p-0 pb-3 h-100 position-relative">
                        <div className="row g-0 h-100">
                          <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around fw-bold">Net Payable Amount </div>
                          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                            {orderDetail.currency} {grandTotal}
                          </div>
                        </div>
                      </div>
                    </Card.Footer> */}
                    <Card.Header style={{ padding: '15px' }}>
                      {(orderDetail?.transactions?.header || []).map((item, index) => {
                        return (
                          <div className="row g-0 h-100" key={index}>
                            {/* Left Side Start */}
                            <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around fw-bold">{item.title}</div>
                            {/* Left Side End */}

                            {/* Right Side Start */}
                            <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">{item.value}</div>
                            {/* Right Side End */}
                          </div>
                        );
                      })}
                    </Card.Header>
                    <Card.Body style={{ padding: '15px' }}>
                      {(orderDetail?.transactions?.body || []).map((item, index) => {
                        return (
                          <div className="container-fluid p-0 pb-3 h-100 position-relative" key={`body-${index}`}>
                            <div className="row g-0 h-100">
                              <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around fw-bold">{item.title}</div>
                              <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">{item.value}</div>
                            </div>
                          </div>
                        );
                      })}
                    </Card.Body>
                    <Card.Footer style={{ padding: '15px' }}>
                      {(orderDetail?.transactions?.footer || []).map((item, index) => {
                        return (
                          <div className="container-fluid p-0 pb-3 h-100 position-relative" key={`footer-${index}`}>
                            <div className="row g-0 h-100">
                              <div className="offset-0 col-12 d-none d-lg-flex offset-md-0 col-lg h-lg-100 justify-content-around fw-bold">{item.title} </div>
                              <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">{item.value}</div>
                            </div>
                          </div>
                        );
                      })}
                    </Card.Footer>{' '}
                  </Card>
                </Col>
              </Row>
              {/* Customer Details End */}
            </div>
            {/* Title End */}
          </Col>
          <Tab.Container defaultActiveKey="Support">
            <Nav variant="tabs" className="nav-tabs-title nav-tabs-line-title" activeKey="Support" as={ResponsiveNav}>
              <Nav.Item>
                <Nav.Link eventKey="Support">Support Tickets</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Messages">Customer Messages</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Delivery">Delivery Remarks</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content style={{ minHeight: '310px' }}>
              <Tab.Pane eventKey="Support" className="mt-4">
                <SupportTicketsDetail typeId="Order" typeIdentifier={id} />
              </Tab.Pane>
              <Tab.Pane eventKey="Messages">
                <QuestionWidget shipment={orderDetail?.reference_no} />
              </Tab.Pane>
              <Tab.Pane eventKey="Delivery">
                <DeliveryRemarks
                  remarks={orderDetail?.delivery_remarks}
                  rescheduleDate={orderDetail?.delivery_reschedule_to}
                  cancelRequest={orderDetail?.requested_to_cancel}
                  canceled={orderDetail?.cancelled_by_customer}
                  hideCancelRequest={orderDetail?.order_status === STATUS_DELIVERED}
                  orderReference={id}
                  orderId={orderDetail?.order_id}
                  className="mt-5"
                  refetch={fetchOrderDetail}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <If condition={(orderDetail.order_status === 3 || orderDetail.order_status === 11) && orderDetail.is_supplier_delivery}>
            <div className="float-end">
              <Button size="sm" onClick={() => setShowModal(true)} variant="light" className="mb-3">
                Unable to Deliver
              </Button>
            </div>
          </If>
        </>
      ) : (
        !isOrderLoading && (
          <Card className="mb-5">
            <Card.Body className="sh-50 d-flex align-items-center justify-content-center">
              <div className="text-center">
                <div className="cta-1">There are no Orders</div>
              </div>
            </Card.Body>
          </Card>
        )
      )}
    </>
  );
};

export default OrdersViewDetail;
