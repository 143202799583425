/* eslint no-underscore-dangle: 0 */

import React, { useState, useRef, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { displayErrorToast, displaySuccessToast } from 'helpers/toaster';
import { getCurrentSellerId } from 'auth/utils';
import { updateSellerOrderStatus } from '../actions/orderActions';

const STATUS_DELIVERED = 4;
const STATUS_UNDELIVERED = 10;
const DeliveryDetailsModal = (props) => {
  const [deliveryDate, setDeliveryDate] = useState(null);
  const endDate = new Date();
  const ONE_DAY = 24 * 3600000;
  const startDate = new Date(endDate.getTime() - ONE_DAY);
  const [documentInfo, setDocumentInfo] = useState({ name: 'Proof Of Delivery', url: '' });
  const hiddenFileInput = useRef([createRef()]);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [remarks, setRemarks] = useState('');
  const handleUploadFile = () => {
    hiddenFileInput.current.click();
  };
  const onChangeFile = ({ target: { files } }) => {
    const oldDocumentInfo = { ...documentInfo };
    setDocumentInfo({ ...oldDocumentInfo, file: files?.[0] });
  };

  const dispatch = useDispatch();
  const { order, unDelivered } = props;

  const handleSetDelivered = async (orderStatus) => {
    setWaitingResponse(true);
    const orderItems = order.items.map((item) => ({ sku: item.sku, itemStatus: 3 }));
    const params = {
      orderStatus,
      orderItems,
      orderId: order.order_id,
      sellerId: getCurrentSellerId(),
      orderReference: order.reference_no,
      deliveryDate,
    };
    if (unDelivered) {
      params.statusMessage = remarks;
    }
    // else {
    //   params.pod = documentInfo;
    // }
    if (documentInfo?.file) {
      params.pod = documentInfo;
    }

    const result = await updateSellerOrderStatus({
      params,
      dispatch,
    });
    setWaitingResponse(false);
    if (result && result?.success) {
      displaySuccessToast(result?.message);
      props.onComplete();
      setDeliveryDate(null);
      props.onHide();
    } else {
      displayErrorToast(result?.message);
    }
  };
  return (
    <>
      <Modal {...props}>
        <Modal.Header closeButton>
          <Modal.Title> Delivery Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="4" md="4">
              <label>Delivery Date:</label>
            </Col>
            <Col lg="4" md="4" className="mb-4">
              <DatePicker
                className="form-control"
                autoComplete="off"
                minDate={startDate}
                maxDate={endDate}
                onChange={(date) => setDeliveryDate(date)}
                name="date"
                selected={deliveryDate}
              />
            </Col>
          </Row>

          {unDelivered ? (
            <Row>
              <Col lg="4" md="4">
                <label>Remarks:</label>
              </Col>
              <Col lg="8" md="8">
                <textarea className="form-control w-100 " value={remarks} placeholder="Remarks" onChange={(e) => setRemarks(e.target.value)} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg="4" md="4">
                <Button size="sm" variant="success" onClick={() => handleUploadFile()}>
                  Select POD
                </Button>
              </Col>
              <Col lg="4" md="4">
                <input type="file" className="d-none" onChange={(e) => onChangeFile(e)} ref={hiddenFileInput} />
                {documentInfo?.file && <span className="m-1">{documentInfo?.file?.name}</span>}
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => props.onHide()}>
            Cancel
          </Button>
          {unDelivered ? (
            <Button
              disabled={deliveryDate === null || remarks === '' || waitingResponse}
              variant="success"
              onClick={() => handleSetDelivered(STATUS_UNDELIVERED)}
            >
              {waitingResponse && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              Set Un Delivered
            </Button>
          ) : (
            <Button
              disabled={deliveryDate === null || waitingResponse}
              variant="success"
              onClick={() => handleSetDelivered(STATUS_DELIVERED)}
            >
              {waitingResponse && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              Set Delivered
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeliveryDetailsModal;
